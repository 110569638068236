var tisca = (function () {
	'use strict';

	var self = {

		init: function () {

		}
	};

	return {
		init: self.init
	}
})();

$(document).ready(function () {
	tisca.init();
});